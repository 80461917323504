<template>
  <div class="home">
    <LandingSplash />
    <Section medium>
      <v-col cols="12">
        <h2>2024 Applications are now closed!</h2>
        <p class="apply-p">
          Thanks to all our applicants. Follow us on
          <a target="_blank" href="https://www.instagram.com/yonderdynamics/"
            >Instagram</a
          >
          to stay up to date with the team.
        </p>

        <hr class="solid">
        <br />

        <p>
          Yonder Dynamics is a student robotics organization at UC San Diego
          formally established in Fall 2016. As our primary project, we design
          and build a Mars rover for the annual University Rover Challenge
          (URC), a prestigious international competition for college students.
          Our newest rover, Latency, placed 6th in the world overall at the 2024
          URC world finals. Our team qualified for this competition by
          demonstrating Latency in the following video submission:
        </p>
      </v-col>
      <div class="video-wrap">
        <div class="video-container">
          <iframe
            class="video"
            src=""
            data-src="https://www.youtube.com/embed/UXb0VV7I4PY?si=EMTmbscdD5tIrf2i"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <v-col cols="12">
        <p>
          Above all, our people are our mission. We believe in developing
          individuals from the ground up to prepare them for their future
          endeavors. Now with over sixty active members from a variety of
          backgrounds, we pride ourselves on inclusivity—robotics is for
          everyone.
        </p>
        <p id="signature">- Yonder Dynamics Team</p>
      </v-col>
    </Section>
    <Rocket />
    <SplitSection medium>
      <template v-slot:left>
        <h1>URC</h1>
        <p>
          The University Rover Challenge (URC) is the world's premier robotics
          competition for university students. Held annually at the Mars Desert
          Research Station (MDRS), URC challenges student teams to design and
          build the next generation of Mars rovers that will one day work
          alongside astronauts exploring the Red Planet.
        </p>
      </template>
      <template v-slot:right>
        <v-img class="shrink mr-2" contain :src="require('@/assets/urc.jpg')" />
      </template>
    </SplitSection>
    <Section light>
      <h1>What We've Built</h1>
      <v-col cols="12">
        <p>
          We have built four fully seperate rovers since founding, each time
          improving and iterating on our design.
        </p>
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our first rover, built in 2016, was named "Courage" and was mostly 3d
          printed.
        </p>
        <v-img
          class="shrink mx-auto"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/courage.jpg')"
        />
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our 2017 rover was named "Procyon" and it moved to a more standard and
          robust drive system.
        </p>
        <v-img
          class="shrink mx-auto"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/procyon.jpg')"
        />
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our rover from 2019 through 2021, named "Apathy", featured a heavily
          redesigned and mechanically simplified arm and drive system.
        </p>
        <v-img
          class="shrink"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/apathy.jpeg')"
        />
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our 2022 rover, "Complacency", resulted from iterating the solid
          "Apathy" drive train, adding on top of it a brand new arm, electrical
          system, and science module.
        </p>
        <v-img
          class="shrink mx-auto"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/complacency.jpg')"
        />
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our 2023 rover, "Lethargy", sported a new chassis
          and suspension system, science module, and autonomous state machine
          software.
        </p>
        <v-img
          class="shrink mx-auto"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/lethargy.jpg')"
        />
      </v-col>
      <v-col class="col-lg-4 col-12">
        <p>
          Our current rover, "Latency", improved upon the electrical system
          and controls interface, while also adding a new drill module and
          object detection system.
        </p>
        <v-img
          class="shrink mx-auto"
          :aspect-ratio="1 / 1"
          :max-height="450"
          contain
          :src="require('@/assets/latency.jpg')"
        />
      </v-col>
    </Section>
    <SplitSection medium>
      <template v-slot:right>
        <v-img
          class="shrink mr-2"
          contain
          :src="require('@/assets/team.jpg')"
        />
      </template>
      <template v-slot:left>
        <h1>Join Us</h1>
        <p>
          Applications for the 2024-2025 season are now closed. Thanks to all of
          our applicants.
          <br />
          <br />
          <br />
          Keep up with what we are doing on our
          <a target="_blank" href="https://www.instagram.com/yonderdynamics/"
            >Instagram</a
          >
          or
          <a target="_blank" href="https://www.facebook.com/yonderdynamics/"
            >Facebook</a
          >
          pages.
        </p>
      </template>
    </SplitSection>
    <Sponsors />
  </div>
</template>
<script>
import LandingSplash from '@/components/LandingSplash.vue';
import Section from '@/components/Section.vue';
import SplitSection from '@/components/SplitSection.vue';
import Sponsors from '@/components/Sponsors.vue';
import Rocket from '@/components/Rocket.vue';

export default {
  name: 'Home',
  components: {
    LandingSplash,
    Section,
    SplitSection,
    Sponsors,
    Rocket,
  },
  mounted() {
    const vidDefer = document.getElementsByTagName('iframe');
    for (let i = 0; i < vidDefer.length; i += 1) {
      if (vidDefer[i].getAttribute('data-src')) {
        vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
      }
    }
  },
};
</script>
<style scoped>
.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.apply-p {
  border-bottom: 5px;
  padding-bottom: 5px;
}
.video-wrap {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
#signature {
  float: right;
  font-size: 2em;
}
#rocket {
  background-color: gray;
  height: 400px;
}
a {
  color: white;
}
a:visited {
  color: white;
}
a:link {
  color: white;
}
</style>
